import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import ContactSalesLDP from "~/components/molecules/ContactSalesLDP";
import { IContentSection } from "~/components/organisms/ContentsNavigator/types";
import HeaderLDP from "~/components/organisms/HeaderLDP";
import HeaderMobileLDP from "~/components/organisms/HeaderMobileLDP";
import MastheadGalleryLDP from "~/components/organisms/MastheadGalleryLDP";
import SectionProjectDetailsLDP from "~/components/sections/SectionProjectDetailsLDP";
import SectionProjectLocationMapLDP from "~/components/sections/SectionProjectLocationMapLDP";
import SectionProjectMasonryGalleryLDP from "~/components/sections/SectionProjectMasonryGalleryLDP";
import SectionProjectSitePlanLDP from "~/components/sections/SectionProjectSitePlanLDP";
import SectionProjectVirtualTourLDP from "~/components/sections/SectionProjectVirtualTourLDP";
import SectionUnitsLDP from "~/components/sections/SectionUnitsLDP";
import { useIsClient } from "~/hooks/use-is-client";
import useMenuScroll from "~/hooks/use-menu-scroll";
import { THomeLoaderData } from "~/types/homeLoaderData";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { Section, SectionIndex } from "~/utilities/enums/ProjectSections";

export interface SectionsLDPProps {}

export type Ref = HTMLDivElement;

const SectionsLDP = forwardRef<Ref, SectionsLDPProps>((_, ref) => {
  const {
    config,
    logoHeader,
    topButton,
    userConfig: user,
  } = useRouteLoaderData("root") as IRouteLoaderData;
  const {
    projectMenuItems,
    project,
    floorPlansPagination,
    unitTransactions,
    // curatedProjects,
    virtualTours,
    saleTransactionFilterOptions,
    floorPlanFilterOptions,
  } = useLoaderData<THomeLoaderData & TProjectLoaderData>();

  const isClient = useIsClient();

  const { t } = useTranslation();

  const validProjectMenuItems = useMemo(() => {
    if (!isClient) return projectMenuItems;
    return projectMenuItems
      ?.filter((item) => !!document.getElementById(item.targetId))
      ?.map((item) => {
        const isExist = !!document.getElementById(item.targetId);
        return {
          ...item,
          disabled: !isExist,
        };
      });
  }, [isClient, projectMenuItems]);

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const { activeIndex, scrollToSection, menuRef } = useMenuScroll({
    sections: sectionRefs.current,
  });

  return (
    <main
      className="flex min-h-screen w-full flex-col items-center bg-backgroundPageProject"
      ref={ref}
    >
      {!isEmpty(validProjectMenuItems) && (
        <>
          <HeaderLDP
            logo={logoHeader}
            project={project}
            topButton={topButton}
            sections={validProjectMenuItems || ([] as IContentSection[])}
            defaultSection={
              validProjectMenuItems?.[0] || ({} as IContentSection)
            }
          />
          <HeaderMobileLDP
            logo={logoHeader}
            project={project}
            projectMenuItems={
              validProjectMenuItems || ([] as IContentSection[])
            }
            phoneNumber={config?.phoneNumber || ""}
            socialLinks={config?.socialLinks || []}
            topButton={config?.topButton}
            top={0}
            isSticky={true}
          />
        </>
      )}
      {(project?.medias?.length > 0 || project?.photo) && (
        <MastheadGalleryLDP
          project={project}
          onShowPriceList={() => {}}
          onShowFlat={() => {}}
        />
      )}
      <SectionProjectDetailsLDP
        ref={(el) => (sectionRefs.current[SectionIndex.PROJECT_DETAILS] = el!)}
        id={Section.PROJECT_DETAILS}
        title={projectSectionConfig[Section.PROJECT_DETAILS].title}
        project={project}
        className="ldp"
      />
      {virtualTours?.length > 0 && (
        <div className="w-full  overflow-hidden">
          <SectionProjectVirtualTourLDP
            ref={(el) => (sectionRefs.current[SectionIndex.VIRTUAL_TOUR] = el!)}
            id={Section.VIRTUAL_TOUR}
            title={projectSectionConfig[Section.VIRTUAL_TOUR].title}
            description={projectSectionConfig[Section.VIRTUAL_TOUR].description}
            virtualTours={virtualTours}
            project={project}
            unitTypeOptions={project?.unitTypes?.map((unitType) => ({
              id: unitType?.id,
              name: unitType?.title,
            }))}
          />
        </div>
      )}
      {(project?.amenityHtml || project?.googleMapUrl) && (
        <SectionProjectLocationMapLDP
          id={Section.LOCATION_MAP}
          title={projectSectionConfig[Section.LOCATION_MAP].title}
          description={projectSectionConfig[Section.LOCATION_MAP].description}
          project={project}
          className="ldp py-20 lg:py-12"
        />
      )}
      <SectionUnitsLDP
        project={project}
        floorPlansPagination={floorPlansPagination}
        floorPlanFilterOptions={floorPlanFilterOptions}
        unitTransactions={unitTransactions}
        saleTransactionFilterOptions={saleTransactionFilterOptions}
        className="ldp"
      />
      <ContactSalesLDP
        id={Section.CONTACT_SALES}
        title={t("contact_sales.heading")}
        user={user}
        className="ldp"
      />
      {(project?.sitePlanImage || project?.facilityHtml) && (
        <SectionProjectSitePlanLDP
          ref={(el) => (sectionRefs.current[3] = el!)}
          id={Section.SITE_PLAN}
          title={projectSectionConfig[Section.SITE_PLAN].title}
          description={projectSectionConfig[Section.SITE_PLAN].description}
          project={project}
          className="ldp"
        />
      )}
      {project?.medias?.length > 0 && (
        <SectionProjectMasonryGalleryLDP
          id={Section.GALLERY}
          title={projectSectionConfig[Section.GALLERY].title}
          project={project}
          className="ldp"
        />
      )}

      {/* <SectionScrollToEnquiry
          id={Section.SCROLL_TO_ENQUIRY}
          title={projectSectionConfig[Section.SCROLL_TO_ENQUIRY].title}
          description={
            projectSectionConfig[Section.SCROLL_TO_ENQUIRY].description
          }
          className="container-content my-6 lg:my-0"
        /> */}
      {/* {curatedProjects?.length > 0 && (
          <SectionCuratedProperties
            id={Section.CURATED_PROPERTIES}
            title="project.curated_properties"
            className="mt-6"
            projects={curatedProjects}
            handleOnSelectProject={onCloseModalState}
          />
        )} */}
      {/* <RelatedPropertiesModal
        setRelatedProjectId={setRelatedProjectId}
        relatedProjects={curatedProjects}
        open={modalState === "projectCurated"}
        onClose={onCloseModalState}
      /> */}
      {/* <ProjectDetailModal
        project={project}
        open={modalState === "unitType"}
        onClose={(ids) => {
          if (curatedProjects.length > 0)
            return setModalState("projectCurated");
          return onCloseModalState();
        }}
        onShowFlat={() => {
          setModalState("contact");
        }}
        onShowPriceList={() => setModalState("priceList")}
      /> */}
      {/* <ProjectDetailContactSale
        project={project}
        open={modalState === "contact" || modalState === "masthead_contact"}
        onClose={onCloseModalState}
      /> */}
      {/* <PriceListModal
        items={floorPlanItems}
        page={fetcher?.data?.floorPlansModalPagination?.currentPage || 1}
        onLoadMore={onLoadMore}
        isLoading={fetcher?.state === "loading"}
        open={modalState === "priceList"}
        onClose={onCloseModalState}
      /> */}
    </main>
  );
});

SectionsLDP.displayName = "SectionsLDP";

export default SectionsLDP;
