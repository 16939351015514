import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import SectionProjectAvailableUnits from "../SectionProjectAvailableUnits";
import SectionProjectFloorPlans from "../SectionProjectFloorPlans";
import SectionProjectSaleTransaction from "../SectionProjectSaleTransaction";

import Button from "~/components/atoms/Button";
import Typo from "~/components/atoms/Typo";
import SectionProjectUnitMix from "~/components/sections/SectionProjectUnitMix";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IProject } from "~/entities/project";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import { IPaginateResponseData } from "~/types";
import { cn } from "~/utilities/cn";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Section } from "~/utilities/enums/ProjectSections";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

interface SectionUnitsLDPProps {
  className?: string;
  project: IProject;
  floorPlansPagination: IPaginateResponseData<IFloorPlan>;
  floorPlanFilterOptions: IFloorPlanFilterOption[];
  unitTransactions: IPaginateResponseData<IUnitTransaction>;
  saleTransactionFilterOptions: ISaleTransactionFilterOption[];
}

const SectionUnitsLDP: React.FC<SectionUnitsLDPProps> = ({
  project,
  floorPlansPagination,
  floorPlanFilterOptions,
  unitTransactions,
  saleTransactionFilterOptions,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full overflow-hidden bg-background py-20 lg:py-12">
      <TabGroup
        vertical
        className={cn(
          "grid grid-cols-4 lg:grid-cols-1 lg:gap-12",
          className,
          "lg:px-0"
        )}
      >
        <TabList className=" col-span-1 flex flex-col items-start justify-start gap-6 lg:flex-row lg:gap-3 lg:overflow-x-auto lg:px-4">
          {project?.unitTypes?.length > 0 && (
            <Tab className="outline-none ring-0">
              {({ selected }) => (
                <Button
                  variant={selected ? "default" : "outline"}
                  className="rounded-full"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={selected ? ETypoColor.WHITE : ETypoColor.TEXT}
                  >
                    {t(projectSectionConfig[Section.UNIT_MIX].title)}
                  </Typo>
                </Button>
              )}
            </Tab>
          )}
          {project?.unitTypes?.length > 0 && (
            <Tab className="outline-none ring-0">
              {({ selected }) => (
                <Button
                  variant={selected ? "default" : "outline"}
                  className="rounded-full"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={selected ? ETypoColor.WHITE : ETypoColor.TEXT}
                  >
                    {t(projectSectionConfig[Section.AVAILABLE_UNITS].title)}
                  </Typo>
                </Button>
              )}
            </Tab>
          )}
          {floorPlansPagination?.total > 0 && (
            <Tab className="outline-none ring-0">
              {({ selected }) => (
                <Button
                  variant={selected ? "default" : "outline"}
                  className="rounded-full"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={selected ? ETypoColor.WHITE : ETypoColor.TEXT}
                  >
                    {t(projectSectionConfig[Section.FLOOR_PLANS].title)}
                  </Typo>
                </Button>
              )}
            </Tab>
          )}
          {!isEmpty(unitTransactions) && (
            <Tab className="outline-none ring-0">
              {({ selected }) => (
                <Button
                  variant={selected ? "default" : "outline"}
                  className="rounded-full"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={selected ? ETypoColor.WHITE : ETypoColor.TEXT}
                  >
                    {t(projectSectionConfig[Section.SALES_TRANSACTION].title)}
                  </Typo>
                </Button>
              )}
            </Tab>
          )}
        </TabList>
        <TabPanels className="col-span-3 bg-backgroundPageProject px-6 lg:col-span-1 lg:px-0">
          {project?.unitTypes?.length > 0 && (
            <TabPanel>
              <SectionProjectUnitMix
                id={Section.UNIT_MIX}
                title={projectSectionConfig[Section.UNIT_MIX].title}
                description={projectSectionConfig[Section.UNIT_MIX].description}
                project={project}
                className="lg:gap-6 lg:py-1.5"
              />
            </TabPanel>
          )}
          {project?.unitTypes?.length > 0 && (
            <TabPanel>
              <SectionProjectAvailableUnits
                id={Section.AVAILABLE_UNITS}
                title={projectSectionConfig[Section.AVAILABLE_UNITS].title}
                description={
                  projectSectionConfig[Section.AVAILABLE_UNITS].description
                }
                project={project}
              />
            </TabPanel>
          )}
          {floorPlansPagination?.total > 0 && (
            <TabPanel>
              <SectionProjectFloorPlans
                id={Section.FLOOR_PLANS}
                title={projectSectionConfig[Section.FLOOR_PLANS].title}
                description={
                  projectSectionConfig[Section.FLOOR_PLANS].description
                }
                floorPlansPagination={floorPlansPagination}
                project={project}
                unitTypeFilterOptions={floorPlanFilterOptions}
              />
            </TabPanel>
          )}
          {!isEmpty(unitTransactions) && (
            <TabPanel>
              <SectionProjectSaleTransaction
                id={Section.SALES_TRANSACTION}
                title={projectSectionConfig[Section.SALES_TRANSACTION].title}
                description={
                  projectSectionConfig[Section.SALES_TRANSACTION].description
                }
                unitTransactions={unitTransactions}
                saleTransactionFilterOptions={saleTransactionFilterOptions}
              />
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default SectionUnitsLDP;
