import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useLocation, useMatches } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ContactUsButton from "../ContactUsButton";
import { IContentSection } from "../ContentsNavigator/types";

import IconChevronDown from "~/components/atoms/Icons/IconChevronDown";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import { useScrollTop } from "~/hooks/use-scroll-top";
import { ILink } from "~/types";
import { cn } from "~/utilities/cn";
import {
  SCROLL_OFFSET,
  SCROLL_OFFSET_MB,
} from "~/utilities/constants/projectSections";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HeaderProps {
  logo?: IMediaImageUrls;
  topButton?: ILink;
  className?: string;
  project: IProject;
  sections: IContentSection[];
  defaultSection: IContentSection;
}

export type Ref = HTMLDivElement;

const HeaderLDP = forwardRef<Ref, HeaderProps>(
  ({ logo, topButton, className, project, sections, defaultSection }, ref) => {
    const scrolled = useScrollTop();
    const { t } = useTranslation();
    const matches = useMatches();
    const isHomePage = matches.some((match) => match.pathname === Slug.HOME);

    const [isFixed, setIsFixed] = useState<boolean>(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsFixed(scrolled);
      }, 400);

      return () => clearTimeout(timer);
    }, [scrolled]);

    const location = useLocation();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
    const [selectedSection, setSelectedSection] = useState(
      defaultSection || sections[0]
    );

    const [currentSection, setCurrentSection] = useState<string>(
      location?.hash?.replace("#", "") || defaultSection?.targetId
    );

    const scrollToView = useCallback(
      (targetId: string, offset: number = SCROLL_OFFSET) => {
        const sectionEl = document.getElementById(targetId);

        if (sectionEl) {
          const y =
            sectionEl.getBoundingClientRect().top + window.scrollY + offset;
          window.scrollTo({ top: y, behavior: "smooth" });

          // Update the URL hash without scrolling again
          window.history.pushState(null, "", `#${targetId}`);
        }

        setCurrentSection(targetId);
      },
      []
    );

    useEffect(() => {
      if (location.hash) {
        const timeoutId = setTimeout(() => {
          scrollToView(
            currentSection,
            isTabletOrMobile ? SCROLL_OFFSET_MB : SCROLL_OFFSET
          );
        }, 1000);

        return () => clearTimeout(timeoutId); // Cleanup to avoid memory leaks
      }
    }, [location.hash, currentSection, isTabletOrMobile, scrollToView]);

    // if (!isHomePage && !isProjectPage && !isSectionsPage) return <></>;

    return (
      <>
        {isHomePage && (
          <header
            ref={ref}
            data-name="Header"
            className={cn(
              `sticky top-0 z-100 block w-screen overflow-hidden bg-backgroundPageProject py-4 transition-all duration-400 lg:hidden`,
              isFixed && "w-full border-b shadow-header",
              className
            )}
          >
            <div className="ldp flex items-center justify-between gap-x-12">
              {/* {logo && (
                <Link to={Slug.HOME}>
                  <ImageResponsive
                    imageData={logo}
                    alt="Brand Logo"
                    className="h-[34px] w-auto origin-left"
                    zoom={1.3}
                    displayDisclaimer={false}
                  />
                </Link>
              )} */}
              <Typo
                tag={ETypoTag.H1}
                variant={ETypoVariant.HEADER_32}
                color={ETypoColor.HEADER_TEXT}
                className="truncate text-center font-bold"
              >
                {project?.name}
              </Typo>
              <div className="flex items-center gap-x-12">
                {!isEmpty(sections) &&
                  sections.slice(0, 3).map(({ targetId, label }) => {
                    const actived = currentSection === targetId;

                    return (
                      <button
                        key={targetId}
                        onClick={() => {
                          scrollToView(
                            targetId,
                            isTabletOrMobile ? SCROLL_OFFSET_MB : SCROLL_OFFSET
                          );
                        }}
                      >
                        <Typo
                          tag={ETypoTag.SPAN}
                          variant={ETypoVariant.HEADER_16}
                          color={ETypoColor.HEADER_TEXT}
                          className={cn(
                            "text-center transition-all duration-200 hover:text-headerTextHover",
                            actived ? "font-bold text-headerTextActive" : ""
                          )}
                        >
                          {t(label)}
                        </Typo>
                      </button>
                    );
                  })}
                <Listbox value={selectedSection} onChange={setSelectedSection}>
                  <ListboxButton className="flex items-center justify-center gap-3">
                    <Typo
                      tag={ETypoTag.SPAN}
                      variant={ETypoVariant.HEADER_16}
                      color={ETypoColor.HEADER_TEXT}
                      className="text-center transition-all duration-200 hover:text-headerTextHover"
                    >
                      {t("other")}
                    </Typo>
                    <IconChevronDown className="text-color" />
                  </ListboxButton>
                  <ListboxOptions
                    anchor="bottom end"
                    className="scroll z-100 w-max min-w-[calc(var(--button-width)+80px)] rounded-xl bg-white pt-1.5 shadow-dropdownSelect focus:outline-none lg:w-[var(--button-width)]"
                  >
                    {sections.slice(3).map((section) => (
                      <ListboxOption
                        key={section.targetId}
                        value={section}
                        className="group flex cursor-pointer select-none items-center p-3 pr-7 data-[focus]:bg-cornflowerBlue lg:p-2"
                      >
                        {({ selected }) => (
                          <button
                            onClick={() => {
                              scrollToView(
                                section.targetId,
                                isTabletOrMobile
                                  ? SCROLL_OFFSET_MB
                                  : SCROLL_OFFSET
                              );
                            }}
                          >
                            <Typo
                              tag={ETypoTag.SPAN}
                              variant={ETypoVariant.HEADER_16}
                              color={ETypoColor.HEADER_TEXT}
                              className={cn(
                                "line-clamp-1 text-left transition-all duration-200 hover:text-headerTextHover",
                                selected
                                  ? "font-bold text-headerTextActive"
                                  : ""
                              )}
                            >
                              {t(section.label)}
                            </Typo>
                          </button>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Listbox>
                <ContactUsButton label={topButton?.label || t("contact_us")} />
              </div>
            </div>
          </header>
        )}
      </>
    );
  }
);

HeaderLDP.displayName = "HeaderLDP";

export default HeaderLDP;
