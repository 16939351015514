import { memo, useRef } from "react";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import FilterBarMobile, {
  EFilterBarMobileVariant,
} from "~/components/molecules/FilterBarMobile";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";

export interface MastheadMobileProps {
  className?: string;
  image: IMediaImageUrls | undefined;
}

const MastheadMobile: React.FC<MastheadMobileProps> = ({
  className,
  image,
}: MastheadMobileProps) => {
  const mastheadRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={mastheadRef}
      className={cn(
        "z-1 w-ful relative hidden h-auto lg:block",
        image && "aspect-[375/200]",
        className
      )}
    >
      <FilterBarMobile variant={EFilterBarMobileVariant.DRAWER} />
      {image && (
        <ImageResponsive imageData={image} className="aspect-[375/200]" />
      )}
    </div>
  );
};

export default memo(MastheadMobile);
