import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import FilterBar, { EFilterBarVariant } from "~/components/molecules/FilterBar";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";

export interface MastheadProps {
  className?: string;
  image: IMediaImageUrls | undefined;
}

const Masthead: React.FC<MastheadProps> = ({ className, image }) => {
  const mastheadRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsSticky(!entry.isIntersecting && !isTabletOrMobile),
      { threshold: 0 }
    );

    const mastheadCurrent = mastheadRef.current;
    if (mastheadCurrent) {
      observer.observe(mastheadCurrent);
    }

    return () => {
      if (mastheadCurrent) {
        observer.unobserve(mastheadCurrent);
      }
    };
  }, [isTabletOrMobile]);

  return (
    <div className="container flex lg:hidden">
      <div
        ref={mastheadRef}
        className={cn("relative w-full  rounded-3xl", className)}
      >
        <ImageResponsive
          imageData={image}
          className="relative h-[540px] w-full overflow-hidden rounded-3xl"
        />
        <div
          ref={searchBarRef}
          className={cn(
            isSticky
              ? "fixed left-0 top-[71px] z-20 w-full animate-slideBottom"
              : "absolute bottom-8 left-0 right-0"
          )}
        >
          <div
            className={cn(
              !isSticky
                ? "after:content[''] wrapper flex flex-col gap-y-3 overflow-visible after:relative after:-right-0 after:top-0 after:h-full after:w-px"
                : ""
            )}
          >
            <FilterBar
              variant={
                isSticky ? EFilterBarVariant.STICKY : EFilterBarVariant.DEFAULT
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Masthead;
